// SCHOOL PICTURES
import schoolPhoto from '../assets/school-pics/classphoto.png';

// STUDENTS
import derrick from '../assets/students/derrick.png'
import elisha from '../assets/students/elisha.png'
import fidel from '../assets/students/fidel.png'
import frank from '../assets/students/frank.png'
import marcus from '../assets/students/marcus.png'
import mary from '../assets/students/mary.png'
import maryann from '../assets/students/maryann.png'
import mercyA from '../assets/students/mercy a.png'
import mercyM from '../assets/students/mercy m.png'
import myghan from '../assets/students/myghan.png'
import nelly from '../assets/students/nelly.png'
import nelson from '../assets/students/nelson.png'
import nevillegrey from '../assets/students/nevillegrey.png'
import reagan from '../assets/students/reagan.png'
import rehema from '../assets/students/rehema.png'
import shaneez from '../assets/students/shaneez.png'
import shantel from '../assets/students/shantel.png'
import sheila from '../assets/students/sheila.png'
import valeria from '../assets/students/valeria.png'
import trevor from '../assets/students/trevor.jpg'
import nouser from '../assets/students/nouser.jpeg'

// FEATURES
import redefining from '../assets/features/redefining.png'
import community from '../assets/features/community.png'
import together from '../assets/features/together.png'
import meaningful from '../assets/features/meaningful.png'
import fees from '../assets/features/fees.png'
import food from '../assets/features/food.png'
import materials from '../assets/features/materials.png'
import curriculum from '../assets/features/curriculum.png'

// PARTNERS
import opsahlGruppen from '../assets/partners/opsahlgruppen.png'
import skullerudSkole from '../assets/partners/skullerud-logo.png'
import wwqa from '../assets/partners/wwqa.png'
import youcanyole from '../assets/partners/youcanyole.png'
import sos from '../assets/partners/sos.png'

// TIMETABLE
import timetable from '../assets/timetable/timetable.png'

// LOGO
import TWLlogo from '../assets/logo/logo-transparent.png'
import vipps from '../assets/logo/scanVipps.png'
import vippsLogo from '../assets/logo/vipps.png'
import paypal from '../assets/logo/paypal-transparent.png'
import donorbox from '../assets/logo/donorbox-logo.png'
import gofundme from '../assets/logo/GoFundMe.png'
import TWAlogo from '../assets/logo/twa_logo.png'

// STUDENTS
import brandonAndStudents from '../assets/school-pics/brandonAndStudents.jpg'
import studentsInClass from '../assets/school-pics/studentsInClass.png'
import girlsEating from '../assets/school-pics/girlsEating.png'

// TEAM
import brandon from '../assets/team/brandon.jpeg'
import magnus from '../assets/team/magnus.jpg'
import justin from '../assets/team/justin.jpg'
import polycarp from '../assets/team/polycarp.jpg'
import rennie from '../assets/team/rennie.jpg'

// ADMISSION
import classroom from '../assets/admission/classroom.jpg'

// DONATE
import uniform from '../assets/donate/uniform.png'
import textbooks from '../assets/donate/textbooks.jpeg'
import eating from '../assets/donate/eating.jpg'
import deskChair from '../assets/donate/deskAndChair.jpg'
import activities from '../assets/donate/activities.jpg'
import deskImg from '../assets/donate/deskImg.png'
import earthImg from '../assets/donate/earthImg.png'
import hatImg from '../assets/donate/hatImg.png'
import lunchImg from '../assets/donate/lunchImg.png'
import materialsImg from '../assets/donate/materialsImg.png'
import uniformImg from '../assets/donate/uniformImg.png'
import rent from '../assets/donate/rent.jpg'
import general from '../assets/donate/general.jpg'
import books from '../assets/donate/materials.jpg'

// ABOUT
import klwf from '../assets/about/klwf.png'
import twlInside from '../assets/about/twl-inside.png'
import twlPc from '../assets/about/twl-pc.png'

export default {

    // SCHOOL PICTURES
    schoolPhoto, 

    // STUDENTS
    derrick, elisha, fidel, frank, marcus, mary, maryann, mercyA, mercyM, myghan, nelly, nelson, nevillegrey, reagan, rehema, shaneez, shantel, sheila, valeria, trevor, nouser,

    
    // FEATURES
    redefining, community, together, meaningful, fees, food, materials, curriculum,

    // PARTNERS
    opsahlGruppen, skullerudSkole, wwqa, youcanyole, sos,

    // TIMETABLE
    timetable,

    // LOGO
    TWLlogo, vipps, vippsLogo, paypal, donorbox, gofundme, TWAlogo,

    // STUDENTS
    brandonAndStudents, studentsInClass, girlsEating,

    // TEAM
    brandon, magnus, justin, polycarp, rennie,

    // ADMISSION
    classroom, textbooks, eating, deskChair,

    // DONATE
    uniform, activities, deskImg, earthImg, hatImg, lunchImg, materialsImg, uniformImg, rent, general, books,

    // ABOUT
    klwf, twlInside, twlPc
}