import { Home, Concept, Admission, Donate, Subjects, About, Community } from './container';

export const sharedRoutes = [
    { path: '/', component: Home },
    { path: '/concept', component: Concept },
    { path: '/admission', component: Admission},
    { path: '/donate', component: Donate},
    { path: '/about', component: About},
    { path: '/subjects', component: Subjects},
    { path: '/community', component: Community}
];
