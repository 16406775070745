import React from "react";
import "./GoBack.css";
import { MyLink } from "../../components";

const GoBack = () => {
  return (
    <div className="goback">
      {" "}
      <MyLink to="/">
        <i class="fa-solid fa-arrow-left"></i> Back
      </MyLink>
    </div>
  );
};

export default GoBack;
