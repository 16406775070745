import React, { useEffect } from "react";
import PropTypes from "prop-types";

const HashHandler = ({ onHashChange }) => {
  useEffect(() => {
    const handleHashChange = () => {
      onHashChange(window.location.hash.replace("#", ""));
    };

    window.addEventListener("hashchange", handleHashChange, false);

    // Call on mount in case there is already a hash
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, [onHashChange]);

  return null; // This component does not render anything
};

HashHandler.propTypes = {
  onHashChange: PropTypes.func.isRequired,
};

export default HashHandler;
