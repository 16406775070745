import React from "react";
import { images } from "../../../constants";
import "./Admission.css";
import { Navbar, InfoBar, Footer, GoBack } from "../../../components";

const Admission = () => {
  return (
    <div className="admissions subjects" id="page-container">
      <InfoBar />
      <Navbar />

      <div className="bodyMargin left">
        <GoBack />
        <h1>Admission</h1>

        <p>
          We admit the most motivated and talented students from Kibera who lack
          the necessary means to achieve their greatest potential in school.
        </p>
        <br />
        <div className="blueBackground flex-image-text">
          <div>
            <h2>Selection Criteria</h2>
            <p>
              Students selected to join The Watoto Academy are evaluated with
              the following criteria:
            </p>
            <p>- Motivation</p>
            <p>- Responsible citizenship</p>
            <p>- Academic excellence</p>
            <p>- Financial need</p>
            <p>- Leadership and entrepreneurship potential</p>
            <p>- Critical thinking</p>
            <p>- Problem solving skills</p>
          </div>
          <img src={images.classroom} className="img" alt="" />
        </div>

        <div className="blueBackground">
          <h2>Application</h2>
          <p>Applications are closed for the 2024 school year.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Admission;
