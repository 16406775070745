import React, { useState } from "react";
import "./Navbar.css";
import { images } from "../../constants";
import { MyLink } from "../../components";

const Navbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <div className="navbar">
      <div className="navbar-main">
        <div className="navbar-mobile" onClick={toggleNav}>
          <div>
            <MyLink to="/" onClick={(e) => e.stopPropagation()}>
              <div className="navbar-logo">
                <b>The Watoto Academy</b>
                <br />
                <span>A Watoto Library program</span>
              </div>
            </MyLink>
          </div>

          <div className="navbar-toggle">
            {!isNavVisible && <i className="fa-solid fa-bars"></i>}
            {isNavVisible && <i class="fa-solid fa-x"></i>}
          </div>
        </div>

        <div className={`navbar-items ${isNavVisible ? "show" : ""}`}>
          <MyLink to="/concept">Concept</MyLink>
          <MyLink to="/subjects">Subjects & Timetable</MyLink>
          <MyLink to="/community">Community</MyLink>
          <MyLink to="/about">About</MyLink>
          <MyLink to="/admission">Admission</MyLink>
          <span className="donateLink">
            <MyLink to="/donate">Donate</MyLink>
          </span>
        </div>
        <div className="navbar-main donate">
          <MyLink to="https://www.thewatotolibrary.org" target="_blank">
            <div>
              <img src={images.TWLlogo} className="navbar-img" />
            </div>
          </MyLink>
          <MyLink to="/donate">
            <button className="apply-button">Donate</button>
          </MyLink>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
