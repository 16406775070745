import React, { useState, useEffect } from "react";
import { images } from "../../../constants";
import "./Donate.css";
import { MyLink } from "../../../components";

const MyForm = () => {
  const [formData, setFormData] = useState({
    child: "",
    chosenChildName: "", // Added state for the child's name
    name: "",
    replyTo: "",
    communicationLanguage: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    // Handle form submission
    console.log(formData);
    alert(
      "Thank you very much for changing a vulnerable child's future. We will reach out to you shortly with more information."
    );
  };

  return (
    <form
      acceptCharset="utf-8"
      action="https://submit-form.com/dbx1mHKFA"
      method="post"
      className="sponsorForm"
      onSubmit={handleSubmit}
    >
      {/* <input
        type="hidden"
        name="_redirect"
        value="https://thewatotoadacemy.org/donate#sponsorship"
        target="_blank"
      /> */}
      <input
        type="hidden"
        name="_redirect"
        formaction="https://submit-form.com/dbx1mHKFA"
        method="post"
        value="http://thewatotoacademy.org/donate"
      />
      <p>
        <select
          name="child"
          id="child"
          required
          onChange={handleChange}
          value={formData.child}
        >
          <option value="">Select Child</option>
          <option value="We choose">Random child</option>
          <option value="You choose">I choose the child</option>
        </select>
      </p>

      {formData.child === "You choose" && (
        <>
          <p>
            <input
              type="text"
              name="chosenChildName"
              id="chosen-child-name"
              placeholder="Child's Name"
              onChange={handleChange}
              value={formData.chosenChildName}
            />{" "}
            <p className="studentsLink">
              Meet the children{" "}
              <MyLink to="/community" target="_blank">
                <u>here</u>
              </MyLink>
            </p>
          </p>
        </>
      )}

      <p>
        <input
          type="text"
          name="name"
          id="full-name"
          placeholder="Your Name"
          required
          onChange={handleChange}
          value={formData.name}
        />
      </p>
      <p>
        <input
          type="email"
          name="replyTo"
          id="email-address"
          placeholder="Your E-mail"
          required
          onChange={handleChange}
          value={formData.replyTo}
        />
      </p>
      <p>
        <select
          name="communicationLanguage"
          id="child"
          onChange={handleChange}
          value={formData.communicationLanguage}
        >
          <option value="">Select communication language</option>
          <option value="English">English</option>
          <option value="Norwegian">Norwegian</option>
        </select>
      </p>
      <p>
        <input
          type="text"
          name="requests"
          id="requests"
          placeholder="Comments/requests"
          onChange={handleChange}
          value={formData.comments}
        />
      </p>
      <br />
      <input
        type="hidden"
        name="_subject"
        id="email-subject"
        value="Contact Form Submission"
      />
      <input type="submit" className="button-design form" value="Submit" />
    </form>
  );
};

export default MyForm;
