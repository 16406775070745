import React from "react";
import { images } from "../../../constants";
import "./Donate.css";
import "./MyForm";
import {
  Navbar,
  InfoBar,
  Footer,
  GoBack,
  MyLink,
  SectionToggle,
} from "../../../components";
import MyForm from "./MyForm";

const Donate = () => {
  const toggleSection = (section) => {
    window.location.href = `/donate#${section}`;
    window.scrollTo(0, 0);
  };

  const donationItems = [
    {
      title: "Textbooks & study materials",
      cost: "$180",
      description:
        "covers all 9 required textbooks for 2 children and their study materials.",
      image: images.textbooks,
      fundingStatus: "support needed",
      fundingType: "not",
      link: null,
    },
    {
      title: "Desk & Chair",
      cost: "$1,000",
      description:
        "covers the cost of desk and chair for 20 children, supporting a child's learning for many years.",
      image: images.deskChair,
      fundingStatus: "100% funded by Opsahlgruppen",
      fundingType: "fully",
      link: "https://opsahlgruppen.no/",
    },
    {
      title: "Breakfast & Lunch",
      cost: "$70",
      description:
        "ensures a student receives a nutritious breakfast and lunch for one year.",
      image: images.eating,
      fundingStatus: "support needed",
      fundingType: "not",
      link: null,
    },
    // {
    //   title: "Study Materials",
    //   cost: "$150",
    //   description:
    //     "covers study materials, writing pads and stationaries for 1 child.",
    //   image: images.books,
    //   fundingStatus: "support needed",
    //   fundingType: "not",
    //   link: null,
    // },
    {
      title: "School Uniform",
      cost: "$35",
      description:
        "supports the purchase of a complete school uniform to last a child a whole year.",
      image: images.uniform,
      fundingStatus: "support needed",
      fundingType: "not",
      link: null,
    },
    {
      title: "Activities & field trips",
      cost: "$40",
      description: "covers activity cost and field trip for one student.",
      image: images.activities,
      fundingStatus: "support needed",
      fundingType: "not",
      link: null,
    },
    {
      title: "General expenses",
      cost: "$90",
      description:
        "covers general and administrative expenses for one student.",
      image: images.books,
      fundingStatus: "support needed",
      fundingType: "not",
      link: null,
    },
  ];

  return (
    <div className="subjects" id="page-container">
      <InfoBar />
      <Navbar />
      <div className="bodyMargin left">
        <GoBack />
        <h1>Donate</h1>
        <p>
          Join our efforts to impact the lives of vulnerable children in the
          slums through education. Support us by sponsoring a child, a specific
          need, or any amount.
        </p>

        <SectionToggle
          sections={[
            { id: "donate", label: "Donate" },
            { id: "sponsorship", label: "Our Sponsorship Program" },
            { id: "needs", label: "Our Needs" },
          ]}
        >
          {(activeSection) => (
            <>
              {activeSection === "donate" && (
                <div>
                  <div className="blueBackground">
                    <h2>Donate</h2>
                    <p>
                      We greatly appreciate any donations coming in that will
                      help better the lives of children in Kibera.
                    </p>
                    <p>Asante sana - Thank you very much.</p>
                    <p>
                      If you want to support a specific expense, kindly indicate
                      so in the relevant comment field.
                    </p>
                  </div>
                  <div className="flex-image-text donate blueBackground">
                    <div style={{ alignItems: "left", width: "100%" }}>
                      <h3>GoFundMe</h3>
                      <a
                        className="button-design paypal"
                        id="gofund"
                        href="https://gofund.me/f7f29d32"
                        target="_blank"
                      >
                        Donate through GoFundMe
                      </a>
                      <p>Let's come together and make a difference.</p>
                    </div>
                    <img
                      src={images.gofundme}
                      className="img-nozoom paypal"
                      style={{ marginTop: "-0.3em" }}
                    />
                  </div>
                  <div className="flex-image-text donate blueBackground">
                    <div style={{ alignItems: "left" }}>
                      <h3>Vipps</h3>
                      <a
                        className="button-design paypal"
                        id="vippsPc"
                        href={images.vipps}
                        target="_blank"
                      >
                        Donate through Vipps
                      </a>
                      <a
                        className="button-design paypal"
                        id="vippsMobile"
                        href="https://qr.vipps.no/28/2/05/031/9z2ImrLtM"
                        target="_blank"
                      >
                        Donate through Vipps
                      </a>
                      <p>
                        Vippsnr: 821346 (The Watoto Youth Community And Library
                        Norway)
                        <br />
                        This solution is only available in Norway.
                      </p>
                    </div>
                    <img
                      src={images.vippsLogo}
                      className="img-nozoom paypal"
                      style={{ marginTop: "-0.3em" }}
                    />
                  </div>
                  <div>
                    <div className="flex-image-text donate blueBackground">
                      <div style={{ alignItems: "left", width: "100%" }}>
                        <h3>Donorbox</h3>
                        <a
                          className="button-design paypal"
                          id="donorbox"
                          href="https://donorbox.org/embed/donate-to-the-watoto-library"
                          target="_blank"
                        >
                          Donate through Donorbox
                        </a>
                        <p>
                          For one-time or monthly donations with VISA or
                          Mastercard.
                        </p>
                      </div>
                      <img
                        src={images.donorbox}
                        className="img-nozoom paypal"
                        style={{ width: "15em" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex-image-text donate blueBackground">
                      <div style={{ alignItems: "left", width: "100%" }}>
                        <h3>PayPal</h3>
                        <a
                          className="button-design paypal"
                          href="https://www.paypal.com/donate/?hosted_button_id=F9WFK8JFMH49E"
                          target="_blank"
                        >
                          Donate through PayPal
                        </a>
                        <p>
                          We accept both one-time donations and monthly
                          donations through PayPal.
                        </p>
                      </div>
                      <img
                        src={images.paypal}
                        className="img-nozoom paypal"
                        style={{ width: "15em", marginBottom: "0.8em" }}
                      />
                    </div>
                  </div>
                  <div className="donate blueBackground">
                    <h3>Bank Transfer</h3>
                    <p>
                      Name: The Watoto Youth Community And Library Norway
                      <br />
                      Account number: 1506 93 25022
                    </p>
                    <p>
                      Electronic/paper IBAN: NO55 1506 9325 022
                      <br />
                      Address: DNB Bank ASA, Postboks 1600 Sentrum, 0021 Oslo
                      <br />
                      BIC: DNBANOKKXXX | Swift: DNBANOKK
                    </p>
                  </div>
                </div>
              )}

              {activeSection === "sponsorship" && (
                <div>
                  <div className="blueBackground sponsor">
                    <h2>Sponsor a Child</h2>
                    <p>
                      We are committed to ensuring that our programs are not
                      only meaningful, impactful, and long-term but also
                      accessible to all. Support our cause by adopting a child's
                      academic expenses through:
                    </p>
                    <p>
                      <ul>
                        <li>
                          a monthly donation of{" "}
                          <span className="cost">USD 40 / NOK 400</span>{" "}
                        </li>
                        <li>
                          {" "}
                          or an annual contribution of{" "}
                          <span className="cost">USD 480 / NOK 4800</span>
                        </li>
                      </ul>
                      Your generosity enables us to extend the reach of quality
                      education to even more incredible children facing
                      financial constraints.{" "}
                    </p>
                    <p>
                      Through your sponsorship, we can provide a student with
                      essential items, including a school uniform, shoes,
                      learning materials, and supplies such as course books,
                      exercise books, pens, and pencils, ensuring they are
                      equipped for an entire school year. Moreover, your support
                      allows us to continue offering two nutritious meals a day
                      and organize enriching academic field trips for these
                      students. Thank you for making a lasting impact on the
                      lives of these children through your compassionate
                      contribution.{" "}
                    </p>
                    <br />
                    <h3>Your sponsorship covers</h3>
                    <div className="sponsor-flexbox center">
                      <div className="sponsor-child">
                        <img src={images.deskImg} alt="" />
                        <p>School fees, desk & chair</p>
                      </div>
                      <div className="sponsor-child">
                        <img src={images.uniformImg} alt="" />
                        <p>School uniform & shoes</p>
                      </div>
                      <div className="sponsor-child">
                        <img src={images.materialsImg} alt="" />
                        <p>Books & learning materials</p>
                      </div>
                      <div className="sponsor-child">
                        <img src={images.lunchImg} alt="" />
                        <p>Breakfast & lunch</p>
                      </div>
                      <div className="sponsor-child">
                        <img src={images.earthImg} alt="" />
                        <p>Modern education</p>
                      </div>
                    </div>
                    <p>
                      You will receive updates on your child's progress in
                      school twice a year, along with messages from the child.
                      You will be able to engage as much or as little as you
                      want with your sponsored child. Your support will make a
                      profound difference in transforming the future of a
                      special young child and their family.
                    </p>
                  </div>
                  <div className="blueBackground">
                    <h3>Start a sponsorship</h3>
                    <MyForm />
                  </div>
                </div>
              )}

              {activeSection === "needs" && (
                <div>
                  <div className="blueBackground">
                    <h2>Our Expenses</h2>
                    <p>
                      Support our small needs by donating any amount to enable
                      us to continue making an impactful and meaningful
                      transformation in the lives of every one of our students
                      by providing them with a holistic junior secondary school
                      education. Your support means the world to us.
                    </p>
                    <p>
                      Every amount counts. Let’s make a difference together.
                    </p>
                    <p>
                      Gift us these small packages that are very important to us
                      to continue touching even more lives. One child at a time,
                      together we can. We wish to continue offering a safe and
                      conducive environment for the students to envision and see
                      a better future for themselves and their loved ones.{" "}
                    </p>
                  </div>

                  <div className="twoCols">
                    {donationItems.map((item, index) => (
                      <div className="flex-image-text half" key={index}>
                        <div>
                          <h3>{item.title}</h3>
                          <span className="cost-txt">
                            <p>{item.cost}</p>
                            <p>{item.description}</p>
                          </span>
                          <span>
                            <p className={`funded ${item.fundingType}`}>
                              {item.fundingType === "not" ? (
                                <i class="fa-solid fa-battery-empty"></i>
                              ) : item.fundingType === "partially" ? (
                                <i class="fa-solid fa-battery-half"></i>
                              ) : (
                                <i className="fa-regular fa-circle-check"></i>
                              )}{" "}
                              {item.fundingType === "fully" ? (
                                <MyLink to={item.link} target="_blank">
                                  {item.fundingStatus}
                                </MyLink>
                              ) : (
                                <>{item.fundingStatus}</>
                              )}
                            </p>
                            {(item.fundingType === "not" ||
                              item.fundingType === "partially") && (
                              <button
                                onClick={() => toggleSection("donate")}
                                className="toggledonate"
                              >
                                Contribute
                              </button>
                            )}
                          </span>
                        </div>
                        <img src={item.image} alt={item.title} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </SectionToggle>
      </div>
      <Footer />
    </div>
  );
};

export default Donate;
