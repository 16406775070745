import React from "react";
import "./Footer.css";
import MyLink from "../MyLink/MyLink";

const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="container-footer">
          <div className="container-row">
            <div className="footer-col">
              <MyLink to="/">
                <h4>
                  <a style={{ color: "white" }}>
                    <span className="notranslate">The Watoto Academy</span>
                  </a>
                </h4>
              </MyLink>

              <ul>
                <MyLink to="/">
                  <li>
                    <a>Home</a>
                  </li>
                </MyLink>
                <MyLink to="/subjects">
                  <li>
                    <a>Subjects & Timetable</a>
                  </li>
                </MyLink>
              </ul>
            </div>
            <div className="footer-col">
              <MyLink to="/about">
                <h4>
                  <a style={{ color: "white" }}>About</a>
                </h4>
              </MyLink>

              <ul>
                <MyLink to="/concept">
                  <li>
                    <a>Concept</a>
                  </li>
                </MyLink>

                <MyLink to="/community">
                  <li>
                    <a>Community</a>
                  </li>
                </MyLink>
              </ul>
            </div>
            <div className="footer-col">
              <MyLink to="/donate">
                <h4>
                  <a style={{ color: "white" }}>Engage</a>
                </h4>
              </MyLink>
              <ul>
                <MyLink to="/admission">
                  <li>
                    <a>Admission</a>
                  </li>
                </MyLink>

                <MyLink to="/donate">
                  <li>
                    <a>Donate</a>
                  </li>
                </MyLink>
              </ul>
            </div>
            <div className="footer-col">
              <h4>follow us</h4>
              <div className="social-links">
                <a
                  href="https://www.facebook.com/thewatotolibraryprojectkenya"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.instagram.com/thewatotolibrary/"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCBiw_orAp_qbgRQc-_zUZ7w"
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
              <ul>
                <li>
                  <p>@thewatotolibrary</p>
                  {/* <MyLink to="login">
                <p className="developed">Admin login</p>
              </MyLink> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-end">
          <div className="footer-item left">
            © 2024 The Watoto Library | Academy
          </div>
          <div className="footer-item center">
            <span className="item-block">KBR/CBO/5/4/2023(439) (Kenya)</span>
            <span class="separator">|</span>
            <span className="item-block">Org. nr. 931 464 817 (Norway)</span>
          </div>
          <div className="footer-item right">
            Developed and designed by Magnus Heide
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
