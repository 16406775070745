import React from "react";
import "./Feature.css";

// The Feature component accepts props for the icon, title, and description
const Feature = ({ iconClassName, image, title, description }) => {
  return (
    <div className="feature">
      {/* <i className={`feature-icon ${iconClassName}`}></i> */}
      <img src={image} alt="" />
      <h3 className="feature-title">{title}</h3>
      <p className="feature-description">{description}</p>
    </div>
  );
};

export default Feature;
