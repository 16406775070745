import React from "react";
import { images } from "../../../constants";
import "./About.css";
import { Navbar, InfoBar, Footer, MyLink, GoBack } from "../../../components";

const About = () => {
  return (
    <div className="about" id="page-container">
      <InfoBar />
      <Navbar />
      <div className="bodyMargin left">
        <GoBack />
        <h1>About</h1>
        <p>
          The Watoto Academy is a{" "}
          <MyLink to="https://www.thewatotolibrary.org" target="_blank">
            <u>Watoto Library</u>
          </MyLink>{" "}
          program operating under The Watoto Youth Community And Library.
        </p>
        <p>
          We empower and inspire children to be the leaders the world needs
          today — independent and with honest character. We are working towards
          molding and transforming their lives as early as now, to enable them
          to change the world by equipping them with the skills and moral values
          they need. We want them not only to be dreamers, but achievers,
          encouraging them to champion for the betterment of their community
          through participation in community service activities.{" "}
        </p>
        <br />
        <div className="blueBackground about">
          <h2>Our Organization's Story</h2>
          <div className="flex-image-text">
            <div>
              <MyLink to="https://thewatotolibrary.org/" target="_blank">
                <u>
                  <b>The Watoto Library</b>
                </u>
              </MyLink>
              <p>
                The Watoto Youth Community And Library was founded in July 2022.
                Our first program was The Watoto Library, a small children's
                library space that to date has been providing over 300 children
                in Kibera with a free library space. In our library, they come
                together to study with one another, and they have access to
                learning materials such as textbooks, writing pads, and pencils.
                We provide them with a tutor to assist them in achieving better
                grades, feeling loved, valued, and appreciated.
              </p>
            </div>
            <img src={images.twlInside} className="img" alt="" />
          </div>
          <div className="flex-image-text">
            <div>
              <MyLink
                to="https://thewatotolibrary.org/programs"
                target="_blank"
              >
                <u>
                  <b>Activities</b>
                </u>
              </MyLink>
              <p>
                On weekends, we make the library space more fun and playful
                through our other activities, such as dance classes that run on
                both days of the weekend and bring together the children as they
                strive to learn new dance moves. We also involve the children in
                many different board games, such as chess, that force the
                children to be critical thinkers. Thanks to our partner school,
                Skullerud skole, we were able to receive computer donations that
                have enabled us to have weekly computer class trainings with the
                children at the library and our newly founded school.
              </p>
            </div>
            <img src={images.twlPc} className="img" alt="" />
          </div>
          <div className="flex-image-text">
            <div>
              <MyLink
                to="https://thewatotolibrary.org/programs/klwf"
                target="_blank"
              >
                <u>
                  <b>Kibera Local Water Forum</b>
                </u>
              </MyLink>
              <p>
                In November 2022, we founded Kibera Local Water Forum,
                comprising women, youths, and children who, every Saturday of
                the week, come together to change the water quality of the
                heavily polluted Nairobi River by conducting regular river
                clean-up activities. This river has been turned into a dumping
                site by the community, fully contaminated with all sorts of
                garbage from plastic to human waste. Thanks to our dedicated
                team, we are slowly witnessing the section of the river that we
                have adopted to changing and improving.
              </p>
            </div>
            <img src={images.klwf} className="img" alt="" />
          </div>
          <div className="flex-image-text">
            <div>
              <b>The Watoto Academy</b>
              <p>
                We champion quality education for all, particularly for children
                from very needy and vulnerable backgrounds. We have established
                a school for them that mentors them to be thinkers, leaders,
                doers, and achievers. This school provides them with the
                opportunity to realize their dreams of a better future for
                themselves and their families, while instilling in them values
                that champion for a better community for all. We provide the
                children with a learning space, school uniform and shoes, and
                academic supplies, as well as two nutritious meals a day as they
                strive in their studies.
              </p>
            </div>
            <img src={images.studentsInClass} className="img" alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
