import React, { useState, useEffect, useCallback } from "react";

const SectionToggle = ({ sections, children }) => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  const handleHashChange = useCallback(() => {
    const hash = window.location.hash.replace("#", "");
    const sectionExists = sections.some((section) => section.id === hash);
    if (sectionExists) {
      setActiveSection(hash);
    } else {
      setActiveSection(sections[0].id);
    }
  }, [sections]);

  useEffect(() => {
    window.addEventListener("hashchange", handleHashChange);
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [handleHashChange]);

  const toggleSection = (sectionId) => {
    window.location.hash = sectionId;
  };

  return (
    <div>
      <div className="switchBtns-container">
        {sections.map(({ id, label }) => (
          <h3
            key={id}
            className={`dropdown-button ${
              activeSection === id ? "active-dropdown" : ""
            }`}
            onClick={() => toggleSection(id)}
          >
            {label}
            <i
              className={`fa-solid fa-chevron-${
                activeSection === id ? "up" : "down"
              }`}
            ></i>
          </h3>
        ))}
      </div>

      {children(activeSection)}
    </div>
  );
};

export default SectionToggle;
