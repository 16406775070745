import React, { useState, useCallback, useEffect } from "react";
import "./Subjects.css";
import { images } from "../../../constants";
import {
  Navbar,
  InfoBar,
  Footer,
  GoBack,
  SectionToggle,
} from "../../../components";

const Subjects = () => {
  return (
    <div className="subjects" id="page-container">
      <InfoBar />
      <Navbar />

      <div className="bodyMargin left content">
        <GoBack />
        <h1>Subjects and Timetable</h1>
        <p>
          The Watoto Academy follows the new CBC curriculum (Competency Based
          Curriculum) set out by the Kenya Ministry of Education.
        </p>

        <SectionToggle
          sections={[
            { id: "timetable", label: "Timetable" },
            { id: "subjects", label: "Subjects" },
            ,
          ]}
        >
          {(activeSection) => (
            <>
              {activeSection === "subjects" && (
                <div className="blueBackground">
                  <h2>Our Subjects</h2>
                  <table className="subjectstable">
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <b>
                              Mandatory Subjects{" "}
                              <i class="fa-solid fa-thumbtack"></i>
                            </b>
                            <p>1. English (ENG)</p>
                            <p>2. Kiswahili (KISW)</p>
                            <p>3. Mathematics (MATH)</p>
                            <p>4. Integrated Science (INTE SC.)</p>
                            <p>5. Social Studies (SST)</p>
                            <p>6. Business Studies (BS)</p>
                            <p>7. Agriculture and Nutrition (AGRI & N)</p>
                            <p>
                              8. Pre-Technical and Pre-Career Studies (PRE-TECH)
                            </p>
                            <p>9. Religious Studies Education (CRE)</p>
                            <p>10. Physical Education (P.E.)</p>
                          </p>
                        </td>

                        <td>
                          <p>
                            <p>
                              <b>
                                Elective Subjects{" "}
                                <i class="fa-solid fa-layer-group"></i>
                              </b>
                              <br />
                              <p>1. Visual Arts</p>
                              {/* <p className="grey">2. Performing Arts</p>
                         <p className="grey">3. Home Science</p> */}
                              <p>2. Computer Science</p>
                              {/* <p className="grey">5. Languages</p> */}
                            </p>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {activeSection === "timetable" && (
                <div className="blueBackground">
                  <h2>Timetable</h2>
                  <p>
                    Timetable for The Watoto Academy | Grade 7, Term 1, 2024:
                  </p>
                  <br />
                  <img src={images.timetable} className="timetable" />
                  <p>
                    ASM = Morning assembly | ECA = Extra-curricular activities
                  </p>
                </div>
              )}
            </>
          )}
        </SectionToggle>
      </div>

      <Footer />
    </div>
  );
};

export default Subjects;
