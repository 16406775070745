import React from "react";
import "./InfoBar.css";
import { MyLink } from "../../components";

const InfoBar = () => {
  return (
    <div className="info-bar">
      <div className="info-item">
        <i className="fas fa-map-marker-alt"></i>
        <span>Kibera Drive, Kibera | Nairobi</span>
      </div>
      <div className="info-item pointer">
        <i class="fa-regular fa-envelope"></i>
        <MyLink to="mailto:education@thewatotolibrary.org">
          education@thewatotolibrary.org
        </MyLink>
      </div>
      <div className="info-item">
        <i className="fas fa-clock"></i>
        <span>Mon - Fri | 8 AM - 5 PM</span>
      </div>
    </div>
  );
};

export default InfoBar;
