import React, { useEffect } from 'react';
import './App.css';
import './index.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { sharedRoutes } from './sharedRoutes';

function App() {

  return (
    <div className="App">
      <Routes basename="/the-watoto-academy">
        {sharedRoutes.map(route => (
          <Route 
            key={route.path} 
            path={route.path} 
            element={React.createElement(route.component, { isAdmin: route.isAdmin })} 
          />
        ))}   
          <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
