import React from "react";
import { images } from "../../constants";
import { MyLink } from "../../components";
import "./SplitView.css"; // Make sure to create a corresponding CSS file for styling

const SplitView = () => {
  return (
    <div className="split-view">
      <div className="left-side">
        <h1>
          <div className="inline-group">
            <span>A School.</span> <span>A Community.</span>{" "}
            <span>A Movement.</span>
          </div>
        </h1>

        <p>
          The Watoto Academy provides a holistic junior secondary school to
          children and families that lack financial resources. Here, the
          children have access to qualified teachers who engage them in a
          child-friendly environment and cover with them a holistic curriculum
          that enables them to be thinkers, achievers, and doers. Our students
          receive a full scholarship and everything they need to successfully do
          their studies for free. This includes tuition fees covered, as well as
          school uniform, materials and nutritious meals.
        </p>
        <p>
          The Watoto Academy aspires to provide a comprehensive and exceptional
          educational program that nurtures holistic development, instills deep
          values of love and respect for one another, and upholds a strong
          commitment to serving the community. The children are provided with
          school uniforms, textbooks, study, and writing materials that will
          enable them to showcase and utilize their creativity to make them
          innovative in solving some of the community challenges. The children
          are all fully sponsored, and they, together with their parents and
          relatives, take part in community service activities.
        </p>
      </div>
      <div className="right-side">
        <img src={images.brandonAndStudents} className="img" alt="" />
      </div>
    </div>
  );
};

export default SplitView;
